<template>
  <div v-if="dataList && dataList.length > 0">
    <FileViewItem
      v-for="(fileItemData, index) in dataList"
      :key="index"
      :index="index"
      :fileItemData="isComments ? fileItemData.attachFile : fileItemData"
      :isComments="isComments"
      :comments="fileItemData.comments"
      @fileDownload="fileDownload"
    />
    <!-- <div class="file_view"
      v-for="(file, key) in dataList"
      :key="key">
      <button type="button" class="btn_delete file_sm" :click="removeFile(key, file)">
        <span :class="[ 'ico_account ico_filename', getClassByFileType(file) ]">{{ file.attachType }}</span>
        <div class="group_filename">
          <span class="txt_type">.{{ file.attachExt }}</span>
          <span class="txt_file">
            <span class="inner_txt_file" ref="fileNameEl">{{ fileName(file) }}</span>
          </span>
        </div>
        <span class="ico_account ico_cross">삭제</span>
      </button>
      <input
        v-if="isComments"
        type="text"
        class="inp_comm"
        v-model="file.comments"
        maxLength="40"
        placeholder="첨부파일 상세설명을 입력하세요 (최대 40자)"
      /> -->
  </div>
</template>

<script>
import FileViewItem from "./FileViewItem";
import ApiService from "@/services/ApiService";
export default {
  name: "FileView",
  components: {
    FileViewItem,
  },
  props: {
    dataList: Array,
    isComments: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    async fileDownload(file) {
      const res = await ApiService.shared.getFile(
        `${this.$apiPath.ATTACH_FILE}/${file.sysFilePath}`,
      );

      this.downloadFile(res, file);
    },
    downloadFile(blob, item) {
      const newBlob = new Blob([blob]);

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }

      const data = window.URL.createObjectURL(newBlob);
      const link = document.createElement("a");
      link.href = data;
      link.download = item.orgFile || "download";
      link.click();
    },
  },
};
</script>

<style scoped>
.file_comm {
  display: inline-block;
  position: relative;
}
.file_comm label {
  display: block;
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  cursor: pointer;
}
.file_comm input {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0.01;
}
.desc_file_tip {
  display: inline-block;
  padding: 8px;
  font-size: 11px;
  line-height: 16px;
  color: #bbb;
  vertical-align: top;
}
.file_comm.disabled label[disabled="disabled"] {
  color: #bbb;
  background-color: #fbfbfb;
  border-color: #e1e1e1;
}
.file_comm.disabled input[disabled="disabled"] {
  border-color: #e1e1e1;
  color: #bbb;
  background-color: #fbfbfb;
}
.desc_file_tip .ico_exclam_r {
  margin-right: 4px;
}
.desc_file_tip + .desc_file_tip {
  padding-left: 0;
}
</style>
