<template>
  <tr>
    <td>{{ rowData.regDate }}</td>
    <td>{{ rowData.stateCodeName }}</td>
    <!-- <td>{{ rowData.opinion }}</td> -->
    <td v-html="rowData.etc || ''" />
    <td>{{ rowData.regId }}</td>
    <!-- <td>파일첨부 view</td> -->
    <td>
      <FileView :dataList="rowData.inspectFile ? [rowData.inspectFile] : []" />
    </td>
  </tr>
</template>
<script>
import FileView from "@/_approval/components/common/file/FileView";

export default {
  name: "PopInspectHistoryListLine",
  components: {
    FileView,
  },
  props: {
    rowData: Object,
  },
};
</script>
