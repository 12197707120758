<template>
  <div class="layer_body">
    <div class="tbl_comm tbl_view">
      <table>
        <colgroup>
          <col style="width: 180px" />
          <col style="width: 258px" />
          <col style="width: 180px" />
          <col />
        </colgroup>
        <tbody>
          <tr>
            <!-- <th>검수ID</th>
            <td>{{ SelectedInspectDetail.inspectId }}</td>
            <th>검수일</th>
            <td>{{ SelectedInspectDetail.date }}</td>
          </tr>
          <tr>
            <th>금번검수금액</th>
            <td>{{ SelectedInspectDetail.price }}</td>
            <th>검수상태</th>
            <td>{{ SelectedInspectDetail.statusNm }}</td>
          </tr> -->
          </tr>

          <tr>
            <th>검수ID</th>
            <td>{{ SelectedInspectDetail.calculateNum }}</td>
            <th>검수일</th>
            <td>{{ SelectedInspectDetail.inspectCompleteDate }}</td>
          </tr>
          <tr>
            <th>금번검수금액</th>
            <td>{{ SelectedInspectDetail.inspectPrice }}</td>
            <th>검수상태</th>
            <td>{{ stateCodeName(SelectedInspectDetail.stateCode) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="blank_asset3">
      <div class="tbl_comm tbl_list tbl_scroll">
        <div
          class="inner_scroll"
          style="height: calc(100vh - 451px); max-height: 294px; min-height: 49px"
        >
          <table>
            <colgroup>
              <col style="width: 142px" />
              <!-- 일시 -->
              <col style="width: 150px" />
              <!-- 상태 -->
              <col style="width: 184px" />
              <!-- 의견 -->
              <col style="width: 178px" />
              <!-- 담당자 -->
              <col />
              <!-- 첨부파일 -->
            </colgroup>
            <thead>
              <tr>
                <th style="width: 142px">일시</th>
                <th style="width: 150px">상태</th>
                <th style="width: 184px">의견</th>
                <th style="width: 178px">담당자</th>
                <th style="width: 226px">첨부파일</th>
              </tr>
            </thead>
            <tbody>
              <!-- <PopInspectHistoryListLine 
                v-for="item in InspectDetailHistoryList"
                :key="item.inspectId"
                :rowData="item"/> -->
              <PopInspectHistoryListLine
                v-for="item in InspectDetailHistoryList"
                :key="item.regDate"
                :rowData="item"
              />
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PopInspectHistoryListLine from "./PopInspectHistoryListLine";
import inspectStatusList from "@/_approval/constants/inspectStatusList";

import ApiService from "@/services/ApiService";

export default {
  name: "PopInspectDetail",
  components: {
    PopInspectHistoryListLine,
  },
  props: {
    SelectedInspectDetail: Object,
    contractId: String,
  },
  data() {
    return {
      InspectDetailHistoryList: [
        // { date: '2020-11-26', statusNm: '남품완료', opinion:'이상없음', managerNm:'kerry.youbo(유보현)' },
        // { date: '2020-11-28', statusNm: '남품완료', opinion:'이상없음', managerNm:'kerry.youbo(유보현)' },
        // { date: '2020-11-30', statusNm: '남품완료', opinion:'이상없음', managerNm:'kerry.youbo(유보현)' },
        // { date: '2020-12-05', statusNm: '남품완료', opinion:'이상없음', managerNm:'kerry.youbo(유보현)' },
        // { date: '2020-12-20', statusNm: '남품완료', opinion:'이상없음', managerNm:'kerry.youbo(유보현)' },
        // { date: '2020-12-30', statusNm: '남품완료', opinion:'이상없음', managerNm:'kerry.youbo(유보현)' },
        // { date: '2021-01-02', statusNm: '검수완료', opinion:'이상없음으로 검수완료', managerNm:'kerry.youbo(유보현)' },
      ],
    };
  },
  mounted() {
    this.getCalculateHistory();
  },
  methods: {
    stateCodeName(stateCode) {
      const result = inspectStatusList.find((item) => item.code === stateCode);
      if (!result) return "";

      return result.text;
    },
    async getCalculateHistory() {
      const path = `${this.$apiPath.INSPECT}?calculateNum=${this.SelectedInspectDetail.calculateNum}&contractId=${this.contractId}`;

      const res = await ApiService.shared.getData(path);
      if (res.data && res.data.contractInspectHistoryList) {
        this.InspectDetailHistoryList = res.data.contractInspectHistoryList;
      }
    },
  },
};
</script>
