export const INITIAL = "INITIAL";
export const REQUESTER_INSPECT = "REQUESTER_INSPECT";
export const PURCHASE_INSPECT = "PURCHASE_INSPECT";
export const PURCHASE_CONFIRM = "PURCHASE_CONFIRM";
export const INSPECT_REQUEST = "INSPECT_REQUEST";
export const COMPLETE = "COMPLETE";

export const REQUESTER_REJECT = "REQUESTER_REJECT"; // 반려 된 상태.

const inspectStatusList = [
  { code: INITIAL, text: "납품대기" },
  { code: REQUESTER_INSPECT, text: "현업검수완료" },
  { code: PURCHASE_INSPECT, text: "구매팀검수완료" },
  { code: PURCHASE_CONFIRM, text: "정산대기" },
  { code: INSPECT_REQUEST, text: "검수요청" },
  { code: COMPLETE, text: "정산완료" },
];

export default inspectStatusList;
